<template>
  <div class="wrapper_dialog_admin">
    <div class="relative">
      <transition name="fade" mode="out-in" appear>
        <div class="container">
          <div class="top">
            <span>管理员操作【一池管理后台】</span>
            <router-link :to="{name:'AdminForPoolTwo',path:'/AdminForPoolTwo'}">
              <span style="float:right;font-size: 1rem;">PoolTwo后台</span>
            </router-link>
          </div>
          
          <div class="wallet_items">
            <fieldset>
              <legend>今日</legend>
              <div class="desc_admin">
                <span>节点个奖励：{{formatAmountMethod(nodeRewardToday)}}</span>
                <span>挖矿奖励：{{formatAmountMethod(powRewardToday)}}</span>
              </div>
            </fieldset>
            <br/>
            <fieldset>
              <legend>总数量</legend>
              <div class="desc_admin">
                <span>当前注册地址：{{regCount}}</span>
                <span>有效申购算力地址：{{poolInfo._swapUserCount}}</span>
              </div>
              <div class="desc_admin"><span>当前star累计产出：{{formatAmountMethod(poolInfo._totalPowStarMint)+formatAmountMethod(poolInfo._totalNodeStarMint)}}</span></div>
              <!-- // 全网已产出(star) = _totalPowStarMint + _totalNodeStarMint -->
            </fieldset>
            <br/>
            <button @click="chooseWalletForMetaMask" :disabled="getWallet" :class="{disabled_btn:getWallet}">连接钱包</button>
            <div class="msg">{{ address ? "连接成功：" + address : "" }}</div>
            <br/>

            <fieldset>
              <legend>授权</legend>
              <button @click="approveZeed" :disabled="getZeed" :class="{disabled_btn:getZeed}" style="width:32%;float:left;">授权Zeed</button>
              <button @click="approveUsdt" :disabled="getUsdt" :class="{disabled_btn:getUsdt}" style="width:32%;float:left;margin-left: 0.5rem;">授权Usdt</button>
              <button @click="approveStar" :disabled="getStar" :class="{disabled_btn:getStar}" style="width:32%;float:left;margin-left: 0.5rem;">授权Star</button>
            </fieldset>
            <br/>
<!-- 
            <a href="javascript:;" @click="approveZeed">
              <span>approveZeed</span>
            </a>
            <a href="javascript:;" @click="approveUsdt">
              <span>approveUsdt</span>
            </a>
            <a href="javascript:;" @click="approveStar">
              <span>approveStar</span>
            </a> -->
            <fieldset>
              <legend>每日奖励 </legend>
              <!-- <a href="javascript:;" @click="transferSwapBonus">
                <span>每日奖励转入</span>
              </a> -->
              <input class="input_style_admin" type="number" placeholder="算力挖矿每日转入数量"  v-model="powAmount"> 
              <button class="w100" @click="transferSwapBonusForPowAmount" :disabled="getPowerBtn" :class="{disabled_btn:getPowerBtn}">每日奖励转入（算力奖励）</button>
              <hr/>
              <input class="input_style_admin" type="number" placeholder="节点分配每日转入数量"  v-model="nodeAmount"> 
              <button class="w100" @click="transferSwapBonusForNodeAmount" :disabled="getNodeBtn" :class="{disabled_btn:getNodeBtn}">每日奖励转入（节点奖励）</button>
              <hr/>
              <button class="w100" @click="distribSwapBonus" :disabled="getDisBtn" :class="{disabled_btn:getDisBtn}">每日奖励分配</button>
            </fieldset>

            <br/>
            <fieldset>
              <legend>领取奖励开关状态：{{poolInfo._getRewardSwitch?'开':'关'}} </legend>
              <div/>
              <button @click="setRewardSwitch(true)" style="width:49%;float:left;" :disabled="getRewTrBtn" :class="{disabled_btn:getRewTrBtn}">领取奖励（开）</button>
              <button @click="setRewardSwitch(false)" style="width:49%;float:right;" :disabled="getRewfaBtn" :class="{disabled_btn:getRewfaBtn}">领取奖励（关）</button>
            </fieldset>
            
            <!-- <div style="clear: both;"></div>
            <hr/> -->
            <br/>
            <fieldset>
              <legend>申购池可申购金额：{{remainingAmount}} </legend>
              <input class="input_style_admin" type="number" placeholder="可申购额度"  v-model="addCanSwapAmountNum"> 
              <button class="w100" @click="addCanSwapAmount()" :disabled="getAddBtn" :class="{disabled_btn:getAddBtn}">增加可申购金额</button>
            </fieldset>
            <br/>
            <fieldset>
              <legend>提现操作 </legend>
              <input class="input_style_admin" type="number" placeholder="提现USDT数量"  v-model="withdrawUsdtNum"> 
              <button class="w100" @click="withdrawUsdt()" :disabled="getWithDrBtn" :class="{disabled_btn:getWithDrBtn}">提现USDT</button>
              <hr/>
              <input class="input_style_admin" type="number" placeholder="提现zeed数量"  v-model="withdrawSeedNum"> 
              <button class="w100" @click="withdrawSeed()" :disabled="getWithDSeBtn" :class="{disabled_btn:getWithDSeBtn}">提现zeed</button>
              <hr/>
              <input class="input_style_admin" type="number" placeholder="提现Star数量"  v-model="withdrawStarNum"> 
              <button class="w100" @click="withdrawStar()" :disabled="getWithStarBtn" :class="{disabled_btn:getWithStarBtn}">提现Star</button>
            </fieldset>
            <!-- <br/>
            <fieldset>
              <legend>更新节点排行榜 <span v-if="!hasExpire">无</span><span style="color:red;" v-if="hasExpire">【有】</span>过期数据</legend>
              <input class="input_style_admin" type="number" placeholder="更新节点排行榜"  v-model="updateNodeRangeCount"> 
              <button class="w100" @click="updateNodeRange()" :disabled="updateNodeRangeBtn" :class="{disabled_btn:updateNodeRangeBtn}">更新节点排行榜</button>
            </fieldset> -->
            
            
          </div>
          
        </div>
      </transition>
    </div>
  </div>
</template>
<style scoped>
.wrapper_dialog_admin{
  background: #2d3d61;
  padding-bottom: 3rem;
}
.wrapper_dialog_admin .container .top{
  font-size: 2rem;
  padding: 1.6rem;
}
.msg {
  font-size: 1rem;
  color: red;
}
.desc_admin{
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.desc_admin span{
  margin: 1.5rem;
}
.input_style_admin{
  width: 100%;
  display: block;
  padding: 0 1rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.2rem;
  font-weight: 400;
  width: 100%;
  border: 0.1rem solid #4e4d56;
  border-radius: 0.6rem;
  margin-top: 1rem;
  /* color: #E7F3FC; */
  font-family: BebasNeue-Regular;
}
.wrapper_dialog .container .wallet_items button{
  background: url(../../../public/images/box3.png) no-repeat;
  background-size: 100% 100%;
}
.wallet_items a,.wallet_items button{
  font-size: 1.2rem!important;
  border-radius: 0.6rem;
  justify-content: center!important;
}
.w100{
  width: 100%;
  background: url(../../../public/images/box5.png) no-repeat;
  background-size: 100% 100%;
}
</style>
<script>

const starPool = require('../../utils/starPool')

export default {
  name: "WalletDialog",
  props: ["id"],
  data() {
    return {
      powAmount:'103',//每日奖励转入（算力奖励）
      nodeAmount:'10.5',//每日奖励转入（节点奖励）
      formatAmountMethod:starPool.amountConversion,
      ref:'0x68f4d67294bb833d7561a665f2eb1397645586a6',//默认推荐人0x68f4d67294bb833d7561a665f2eb1397645586a6
      address: "",
      canSwapAmount:'',
      nodeRewardToday:0,
      powRewardToday:0,
      remainingAmount:0,
      addCanSwapAmountNum:'',
      withdrawUsdtNum:'',
      withdrawSeedNum:'',
      withdrawStarNum:'',
      poolInfo:{
        _totalPow:0,  // 全网算力
        _totalSwap:0, // 全网质押总额,
        _totalPowStarMint:0, // 算力总挖出star
        _totalNodeStarMint:0, // 节点总挖出start
        _swapUserCount:0, // 购买算力用户数
        _getRewardSwitch:false, // 是否可以领取奖励开关
      },
      regCount:0,//当前注册地址总数量
      getWallet:false, //链接钱包 按钮是否禁用
      getZeed:false, //授权zeed 按钮是否禁用
      getUsdt:false, //授权usdt 按钮是否禁用
      getStar:false, //授权star 按钮是否禁用
      getPowerBtn:false, //每日奖励转入（算力奖励）按钮是否禁用
      getNodeBtn:false, //每日奖励转入（节点奖励）按钮是否禁用
      getDisBtn:false, //每日奖励分配 按钮是否禁用
      getRewTrBtn:false, //领取奖励（开） 按钮是否禁用
      getRewfaBtn:false, //领取奖励（关） 按钮是否禁用
          
      getAddBtn:false, //增加可申购金额 按钮是否禁用
      getWithDrBtn:false, //提现USDT 按钮是否禁用
      getWithDSeBtn:false, //提现zeed 按钮是否禁用
      getWithStarBtn:false, //提现Star 按钮是否禁用
      updateNodeRangeCount:'',//
      updateNodeRangeBtn:false,
      hasExpire:false, // 返回数据增加了hasExpire
    };
  },
  created: function () {
    let that = this
    starPool.rewarTodayInfo().then(res => {
      console.log(res)
      that.nodeRewardToday = res._nodeRewardToday
      that.powRewardToday = res._powRewardToday
      that.hasExpire = res.hasExpire
      that.hasExpire = true
    })

    // 获取申购池可申购金额
    starPool.canSwapAmount().then(res => {
      that.remainingAmount = res
    })

    // 全网算力
    starPool.poolInfo().then(res => {
      console.log(res)
      that.poolInfo = res
    })

    // 获取当前注册地址总数量
    starPool.referelGraphql(`query MyQuery {
      regCountModels {
        count
      }
    }`).then(res=>{
      console.log('获取当前注册地址总数量',res)
      that.regCount = res.data.regCountModels[0].count
    })
  },
  methods: {
    // 连接钱包 MetaMask
    chooseWalletForMetaMask() {
      var that = this;
      that.getWallet = true;
      if (typeof window.ethereum !== "undefined") {
        const provider = window.ethereum;
        // Stpe1 监听（账号改变、链改变、断开连接）
        provider.on("accountsChanged", (accounts) => {
          // 账号改变
          console.log("账号改变", accounts);
        });
        provider.on("chainChanged", (chainId) => {
          // 链改变
          // window.location.reload()
          console.log("链改变", chainId);
        });
        provider.on("disconnect", (code, reason) => {
          // 断开连接
          console.log("断开连接", code, reason);
        });

        // Stpe2 获取当前钱包账号
       
        provider.request({ method: "eth_requestAccounts" }).then((res) => {
          var address = res[0];
          console.log("当前钱包地址：" + address);
          that.address = address;
          localStorage.setItem('address',address);
          this.$toast(this.$t('menu.extractionSucceeded'));
        });
      } else {
        this.$toast("请安装MetaMask钱包");
      }
      that.getWallet = false;
    },
    // 授权Zeed
    approveZeed(){
      this.getZeed = true;
      starPool.approveZeed().then(res => {
        console.log(res)
      })
    },
    // 授权USDT
    approveUsdt(){
      this.getUsdt = true;
      starPool.approveUsdt().then(res => {
        console.log(res)
      })
    },
    // 授权Star
    approveStar(){
      this.getStar = true;
      starPool.approveStar().then(res => {
        console.log(res)
      })
    },
   
    // 每日奖励转入 onlyGov
    transferSwapBonus(){
      starPool.transferSwapBonus('257.5','10.5').then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 每日奖励转入（算力奖励）
    transferSwapBonusForPowAmount(){
      this.getPowerBtn = true;
      let _powAmount = this.powAmount
      starPool.transferSwapBonus(_powAmount.toString(),'0').then(res=>{
        console.log('每日奖励转入（算力奖励）',_powAmount,res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 每日奖励转入（节点奖励）
    transferSwapBonusForNodeAmount(){
      this.getNodeBtn = true;
      let _nodeAmount = this.nodeAmount
      starPool.transferSwapBonus('0',_nodeAmount.toString()).then(res=>{
        console.log('每日奖励转入（节点奖励）',_nodeAmount,res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 每日奖励分配 onlyGov
    distribSwapBonus(){
      this.getDisBtn = true;
      starPool.distribSwapBonus().then(res=>{
        console.log(res,'=========');
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 领取奖励开关
    setRewardSwitch(_switch){
      console.log(_switch)
      if(_switch){
        this.getRewTrBtn = true;
      }else{
        this.getRewfaBtn = true;
      }
      starPool.setRewardSwitch(_switch).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 增加可申购金额
    addCanSwapAmount(){
      this.getAddBtn = true;
      let addCanSwapAmountNum = this.addCanSwapAmountNum
      console.log(addCanSwapAmountNum)
      starPool.addCanSwapAmount(addCanSwapAmountNum.toString()).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 更新节点排行榜
    updateNodeRange(){
      this.updateNodeRangeBtn = true;
      let _count = this.updateNodeRangeCount
      starPool.updateNodeRange(_count.toString()).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // withdrawUsdt
    withdrawUsdt(){
      this.getWithDrBtn = true;
      let _amount = this.withdrawUsdtNum
      starPool.withdrawUsdt(_amount).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // withdrawSeed
    withdrawSeed(){
      this.getWithDSeBtn = true;
      let _amount = this.withdrawSeedNum
      starPool.withdrawSeed(_amount).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    
    // withdrawStar
    withdrawStar(){
      this.getWithStarBtn = true;
      let _amount = this.withdrawStarNum
      starPool.withdrawStar(_amount).then(res=>{
        console.log(res);
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    }

  },
};
</script>
<style scoped></style>
